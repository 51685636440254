.influencerCard {
  box-shadow: 0px 3px 6px #E0E0E0;
  border-radius: 5px; 
  color: #333;
}

.infoBox {
  width: calc(100% - 200px)
}

.profilePic {
  object-fit: contain;
  border-radius: 5px;
  width: 200px;
  height: 200px;
}

.buttonContainer {
  text-align: right;
  margin-top: 8px !important;
}

.denyButton {
    font-weight: 700;
    background: #fff;
    border-radius: 100px;
    border: 2px solid #000;
    cursor: pointer;
    height: 44px;
    padding: 11px 24px;
    color: #000;
    font-size: 17px;
    line-height: 22px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 8px
}