.container {
  height: calc(100vh - 132px);
  min-height: 420px;
}

.logoContainer {
  text-align: center;
}

.buttonDiv {
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loginButton {
  width: 140px;
}

.forgotPWText {
  margin-top: 1em;
  color: #a890fe;
  letter-spacing: 0.2px;
  font-size: 15px;
  line-height: 17px;
  font-family: Georgia;
  font-style: italic;
  font-weight: bold;
}

.emailLabel {
  font-style: normal;
  margin-top: 24px;
  width: 100%;
}

.passwordLabel {
  font-style: normal;
  margin-top: 16px;
  width: 100%;
}

.noPadding {
  padding: 0;
}

.form {
  width: 360px;
}

.textAlignRight {
  text-align: end;
  padding-top: 1em;
}

.filterWidth {
  max-width: 200px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .form {
    width: 90%;
  }
}
